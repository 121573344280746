import React, { Component } from 'react';
//import { ZougglaScan } from '@zouggla/zouggla-react-sdk';
//import { MyModal } from "../lib/mymodal.js";
import '../App.css';
//import { Redirect } from 'react-router';
import autoBind from 'react-autobind';

class Welcome extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      //   isLoggedIn: false,
      //   q1: true,
      //   apiKey: 'iNNn1QjgMP1RTFWvSc93U58SyJj3u91E1upfMMgD',
      //   stageLevel: 'prod'
    };
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>Welcome home</p>
        </header>

        <div />
      </div>
    );
  }
}

export default Welcome;
